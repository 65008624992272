import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
//componentes
const Header = lazy(()=>import('./componentes/header'));
const Footer = lazy(()=>import('./componentes/footer'));
const Loader = lazy(()=>import('./componentes/loader'));
//paginas
const Home = lazy(() => import('./pages/home.js'));

const Bookie_Software = lazy(() => import('./pages/bookie_software.js'));
const Software_Features = lazy(() => import('./pages/software_features.js'));
const Promotions = lazy(() => import('./pages/promotions.js'));
const Products = lazy(() => import('./pages/products.js'));

const About_Us = lazy(() => import('./pages/about_us.js'));
const Contact = lazy(() => import('./pages/contact.js'));
const Error = lazy(() => import('./pages/404.js'));

function App() {
  return (
    <div className="App bg_base_dark text-white">
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Header/>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route path="bookie-software" element={<Bookie_Software />} />
            <Route path="software-features" element={<Software_Features />} />
            <Route path="promotions" element={<Promotions />} />
            <Route path="products" element={<Products />} />
            <Route path="about_us" element={<About_Us />} />
            <Route path="contact" element={<Contact />} />
            <Route path='*' element={<Error />}/>
          </Routes>
          <Footer/>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
